import { getTransKey } from '../../helpers/utils';
import { MemberUpdatingFieldModel } from './type';

type MemberUpdatingModelProps = {};

export const MemberEditModel = {
  model: (props: MemberUpdatingModelProps): MemberUpdatingFieldModel => {
    return {
      firstName: {
        label: getTransKey('member.fields.firstName.label'),
        placeholder: getTransKey('member.fields.firstName.placeholder'),
        inputType: 'Input',
        name: 'firstName',
        rules: { required: getTransKey('member.fields.firstName.required') },
      },
      lastName: {
        label: getTransKey('member.fields.lastName.label'),
        placeholder: getTransKey('member.fields.lastName.placeholder'),
        inputType: 'Input',
        name: 'lastName',
        rules: { required: getTransKey('member.fields.lastName.required') },
      },
      username: {
        label: getTransKey('member.fields.username.label'),
        placeholder: getTransKey('member.fields.username.placeholder'),
        inputType: 'Input',
        name: 'username',
        rules: { required: getTransKey('member.fields.username.required') },
      },
      password: {
        label: getTransKey('member.fields.password.label'),
        placeholder: getTransKey('member.fields.password.placeholder'),
        inputType: 'Password',
        name: 'password',
        rules: {},
      },
    };
  },
};
