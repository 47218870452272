import {
  FieldTimeOutlined,
  OrderedListOutlined,
  ScheduleOutlined,
  SettingOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  WindowsOutlined,
} from '@ant-design/icons';
import { MenuItem } from '@pkg/ui';
import { newsNavItems } from '../../news/constants/consts';

export const baseNavItems: MenuItem[] = [
  {
    key: 'crawler',
    label: 'crawler.title',
    icon: <ScheduleOutlined />,
    children: [
      { key: 'tasks', label: 'crawler.task', icon: <OrderedListOutlined />, slug: 'tasks' },
      { key: 'cronjobs', label: 'crawler.cronjob', icon: <FieldTimeOutlined />, slug: 'cronjobs' },
    ],
  },
  {
    key: 'account',
    label: 'account.title',
    icon: <UserOutlined />,
    children: [
      { key: 'users', label: 'account.user', icon: <UserSwitchOutlined />, slug: 'users' },
      { key: 'members', label: 'account.member', icon: <UserAddOutlined />, slug: 'members' },
    ],
  },
  {
    key: 'widgets',
    label: 'widget.title',
    icon: <WindowsOutlined />,
    slug: 'widgets',
  },
  {
    key: 'settings',
    label: 'setting.title',
    icon: <SettingOutlined />,
    slug: 'settings',
  },
];

export const navItems: MenuItem[] = [...newsNavItems, ...baseNavItems];
