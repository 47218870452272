import { Module } from '@pkg/config';
import { TaskType } from '@pkg/constants';
import { getTransKey } from '../../helpers/utils';
import { targetOptions, TaskCreationFieldModel, taskTypeOptions } from './type';

type TaskCreationModelProps = {};

export const TaskCreationModel = {
  model: (props: TaskCreationModelProps = {}): TaskCreationFieldModel => {
    return {
      type: {
        label: getTransKey('task.form.fields.type.label'),
        placeholder: getTransKey('task.form.fields.type.placeholder'),
        inputType: 'Dropdown',
        name: 'type',
        options: taskTypeOptions,
        value: TaskType.create,
        rules: { required: getTransKey('task.form.fields.type.required') },
      },
      name: {
        label: getTransKey('task.form.fields.name.label'),
        placeholder: getTransKey('task.form.fields.name.placeholder'),
        inputType: 'Input',
        name: 'username',
        rules: { required: getTransKey('task.form.fields.name.required') },
      },
      target: {
        label: getTransKey('task.form.fields.target.label'),
        placeholder: getTransKey('task.form.fields.target.placeholder'),
        inputType: 'Dropdown',
        name: 'target',
        value: 'posts' as Module,
        options: targetOptions,
        rules: { required: getTransKey('task.form.fields.target.required') },
      },
      parent: {
        label: getTransKey('task.form.fields.parent.label'),
        placeholder: getTransKey('task.form.fields.parent.placeholder'),
        inputType: 'Dropdown',
        name: 'parent',
        options: [],
        rules: {},
      },
      sourceUrl: {
        label: getTransKey('task.form.fields.sourceUrl.label'),
        placeholder: getTransKey('task.form.fields.sourceUrl.placeholder'),
        inputType: 'Input',
        name: 'sourceUrl',
        rules: { required: getTransKey('task.form.fields.sourceUrl.required') },
      },
      listSelector: {
        label: getTransKey('task.form.fields.listSelector.label'),
        placeholder: getTransKey('task.form.fields.listSelector.placeholder'),
        inputType: 'Input',
        name: 'listSelector',
        rules: { required: getTransKey('task.form.fields.listSelector.required') },
      },
      itemSelector: {
        label: getTransKey('task.form.fields.itemSelector.label'),
        placeholder: getTransKey('task.form.fields.itemSelector.placeholder'),
        inputType: 'Input',
        name: 'itemSelector',
        rules: { required: getTransKey('task.form.fields.itemSelector.required') },
      },
      nameSelector: {
        label: getTransKey('task.form.fields.nameSelector.label'),
        placeholder: getTransKey('task.form.fields.nameSelector.placeholder'),
        inputType: 'Input',
        name: 'nameSelector',
        rules: { required: getTransKey('task.form.fields.nameSelector.required') },
      },
      imageSelector: {
        label: getTransKey('task.form.fields.imageSelector.label'),
        placeholder: getTransKey('task.form.fields.imageSelector.placeholder'),
        inputType: 'Input',
        name: 'imageSelector',
        rules: { required: getTransKey('task.form.fields.imageSelector.required') },
      },
      descriptionSelector: {
        label: getTransKey('task.form.fields.descriptionSelector.label'),
        placeholder: getTransKey('task.form.fields.descriptionSelector.placeholder'),
        inputType: 'Input',
        name: 'descriptionSelector',
        rules: { required: getTransKey('task.form.fields.descriptionSelector.required') },
      },
      itemUrlSelector: {
        label: getTransKey('task.form.fields.itemUrlSelector.label'),
        placeholder: getTransKey('task.form.fields.itemUrlSelector.placeholder'),
        inputType: 'Input',
        name: 'itemUrlSelector',
        rules: { required: getTransKey('task.form.fields.itemUrlSelector.required') },
      },
      contentSelector: {
        label: getTransKey('task.form.fields.contentSelector.label'),
        placeholder: getTransKey('task.form.fields.contentSelector.placeholder'),
        inputType: 'Input',
        name: 'contentSelector',
        rules: { required: getTransKey('task.form.fields.contentSelector.required') },
      },
      removeContentSelector: {
        label: getTransKey('task.form.fields.removeContentSelector.label'),
        placeholder: getTransKey('task.form.fields.removeContentSelector.placeholder'),
        inputType: 'Input',
        name: 'removeContentSelector',
        rules: {},
      },
    };
  },
};
