import { CronjobCreationBody, Task } from '@models/base';
import { TaskStatus } from '@pkg/constants';
import {
  AcnButton,
  AcnCard,
  AutoForm,
  BreadcrumbItem,
  DropdownOptions,
  baseButton,
  baseScrollbar,
  getModelValues,
  useFormModel,
  useTrans,
} from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { cloneDeep, set } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbar } from '../../components';
import { getModule } from '../../helpers/utils';
import { TaskApi } from '../tasks/api';
import { taskTypeLabel } from '../tasks/type';
import { CronjobApi } from './api';
import { CronjobCreationModel } from './create.model';

export const CronjobCreateContainer = () => {
  const { t } = useTrans();
  const router = useRouter();
  const cronjobApi = CronjobApi();
  const taskApi = TaskApi();

  const formModel = useFormModel(CronjobCreationModel.model, {});

  const form = useForm<CronjobCreationBody>({
    mode: 'all',
    defaultValues: getModelValues(CronjobCreationModel.model),
  });

  const { data } = useQuery<Task[]>({
    queryKey: ['task-verify'],
    queryFn: () => taskApi.get({ status: TaskStatus.verify }).then((rs) => rs.data.data),
    initialData: () => [],
  });

  useEffect(() => {
    if (data && formModel.model) {
      const model = cloneDeep(formModel.model);
      const options: DropdownOptions[] = data.map((r) => ({
        label: r.name + ' - ' + t(taskTypeLabel[r.type] as any),
        value: r._id!,
      }));
      set(model, 'task.options', options);
      formModel.setModel(model);
    }
  }, [data]);

  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('cronjob.title'), href: '/' + getModule(router) },
    { title: t('cronjob.createTitle') },
  ];

  const onCreate = async (data: CronjobCreationBody) => {
    const res = await cronjobApi.create(data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('createSuccess'));
    router.push('/' + getModule(router));
  };

  return (
    <div className="space-y-3">
      <Toolbar items={breadcrumb} />
      <div className={cn('flex gap-3 h-[calc(100vh-175px)]', baseScrollbar)}>
        <FormProvider {...form}>
          <div className={cn('flex-1 space-y-3')}>
            <AcnCard>
              <AcnCard.Title>{t('details')}</AcnCard.Title>
              <AcnCard.Content className="min-h-[calc(100vh-221px)]">
                <div className="space-y-4">
                  <AutoForm model={formModel.model} setModel={formModel.setModel} />
                </div>
              </AcnCard.Content>
            </AcnCard>
          </div>

          <div className="w-[400px] space-y-3">
            <AcnCard>
              <AcnCard.Title>{t('publish')}</AcnCard.Title>
              <AcnCard.Content className="space-x-4">
                <AcnButton className={cn(baseButton, '!bg-blue-500 !text-white')} onClick={form.handleSubmit(onCreate)}>
                  {t('createBtn')}
                </AcnButton>
              </AcnCard.Content>
            </AcnCard>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
