import { getTransKey } from '../../helpers/utils';
import { ChangePasswordFieldsModel } from './types/changePassword';

type ChangePasswordProps = {};

export const ChangePassword = {
  model: (props: ChangePasswordProps = {}): ChangePasswordFieldsModel => {
    return {
      password: {
        label: getTransKey('changePasswordForm.password.label'),
        placeholder: getTransKey('changePasswordForm.password.placeholder'),
        inputType: 'Password',
        name: 'password',
        rules: { required: getTransKey('changePasswordForm.password.required') },
      },
      newPassword: {
        label: getTransKey('changePasswordForm.newPassword.label'),
        placeholder: getTransKey('changePasswordForm.newPassword.placeholder'),
        inputType: 'Password',
        name: 'newPassword',
        rules: {
          required: getTransKey('changePasswordForm.newPassword.required'),
          validate: (value, formValues) => {
            return value === formValues.password ? getTransKey('changePasswordForm.newPassword.match') : undefined;
          },
        },
      },
      confirmPassword: {
        label: getTransKey('changePasswordForm.confirmPassword.label'),
        placeholder: getTransKey('changePasswordForm.confirmPassword.placeholder'),
        inputType: 'Password',
        name: 'confirmPassword',
        rules: {
          required: getTransKey('changePasswordForm.confirmPassword.required'),
          validate: (value, formValues) => {
            return value !== formValues.newPassword
              ? getTransKey('changePasswordForm.confirmPassword.notMatch')
              : undefined;
          },
        },
      },
    };
  },
};
