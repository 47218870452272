import { MemberCreationBody, MemberQueryParams, MemberUpdatingBody } from '@models/base';
import { fetchData } from '@pkg/ui';
import { withAuth } from '@pkg/utils';
import urlcat from 'urlcat';

export const MemberApi = () => {
  return {
    async create(data: MemberCreationBody) {
      return await fetchData(withAuth({ endpoint: 'admin/members', method: 'POST', body: data }));
    },
    async get(query: MemberQueryParams) {
      return await fetchData(withAuth({ endpoint: 'admin/members', query }));
    },
    async getById(id: string) {
      return await fetchData(withAuth({ endpoint: urlcat('admin/members/:id', { id }) }));
    },
    async update(id: string, data: MemberUpdatingBody) {
      return await fetchData(withAuth({ endpoint: urlcat('admin/members/:id', { id }), method: 'PUT', body: data }));
    },
    async restore(ids: string[]) {
      return await fetchData(
        withAuth({ endpoint: urlcat('admin/members/restore', {}), method: 'PATCH', body: { ids } })
      );
    },
    async remove(ids: string[]) {
      return await fetchData(
        withAuth({ endpoint: urlcat('admin/members/remove', {}), method: 'PATCH', body: { ids } })
      );
    },
    async delete(ids: string[]) {
      return await fetchData(
        withAuth({ endpoint: urlcat('admin/members/delete', {}), method: 'DELETE', body: { ids } })
      );
    },
  };
};
