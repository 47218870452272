import { SettingType } from '@pkg/constants';
import { AcnSegmented, BreadcrumbItem, useTrans } from '@pkg/ui';
import { useState } from 'react';
import { Toolbar } from '../../components';
import { Cache } from './Cache';
import { Seo } from './Seo';
import { ThirdParty } from './ThirdParty';
import { Website } from './Website';

type CustomSettingType = 'cache' | SettingType;

export const SettingContainer = () => {
  const { t } = useTrans();
  const breadcrumb: BreadcrumbItem[] = [{ title: t('dashboard.title'), href: '/' }, { title: t('setting.title') }];
  const [active, setActive] = useState<CustomSettingType>('cache');

  return (
    <div className="space-y-3">
      <Toolbar items={breadcrumb} />

      <div className="px-4 py-2 bg-white">
        <AcnSegmented
          className="[&_.ant-segmented-item]:min-w-24"
          options={[
            { label: 'Cache', value: 'cache' },
            { label: 'Website', value: 'website' },
            { label: 'SEO', value: 'seo' },
            { label: 'Third party', value: 'third-party' },
          ]}
          value={active}
          onChange={(value) => setActive(value as CustomSettingType)}
        />
      </div>

      <div className="bg-secondary p-4 h-[calc(100vh-230px)] overflow-y-auto">
        {active === 'cache' && <Cache />}
        {active === 'website' && <Website />}
        {active === 'seo' && <Seo />}
        {active === 'third-party' && <ThirdParty />}
      </div>
    </div>
  );
};
