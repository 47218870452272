import { CacheDeleteBody, SettingQueryParams, SettingUpdatingBody } from '@models/base';
import { fetchData } from '@pkg/ui';
import { withAuth } from '@pkg/utils';
import urlcat from 'urlcat';

export const SettingApi = () => {
  return {
    async deleteCache(body: CacheDeleteBody) {
      return await fetchData(withAuth({ endpoint: 'cache', method: 'DELETE', body }));
    },
    async getSettings(query: SettingQueryParams) {
      return await fetchData(withAuth({ endpoint: 'settings', method: 'GET', query }));
    },
    async updateSetting(id: string, data: SettingUpdatingBody) {
      return await fetchData(withAuth({ endpoint: urlcat('settings/:id', { id }), method: 'PUT', body: data }));
    },
  };
};
