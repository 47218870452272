import { getTransKey } from '../../helpers/utils';
import { cronJobStatusOptions, CronjobUpdatingFieldModel, timeCronOptions } from './type';

type CronjobEditModelProps = {};

export const CronjobEditModel = {
  model: (props: CronjobEditModelProps = {}): CronjobUpdatingFieldModel => {
    return {
      time: {
        label: getTransKey('cronjob.fields.time.label'),
        placeholder: getTransKey('cronjob.fields.time.placeholder'),
        inputType: 'Dropdown',
        name: 'time',
        options: timeCronOptions,
        rules: { required: getTransKey('cronjob.fields.time.required') },
      },
      task: {
        label: getTransKey('task.title'),
        placeholder: getTransKey('cronjob.fields.task.placeholder'),
        inputType: 'Dropdown',
        name: 'task',
        options: [],
        rules: { required: getTransKey('cronjob.fields.task.required') },
      },
      status: {
        label: getTransKey('task.title'),
        placeholder: getTransKey('cronjob.fields.task.placeholder'),
        inputType: 'Dropdown',
        name: 'task',
        options: cronJobStatusOptions,
        rules: { required: getTransKey('cronjob.fields.task.required') },
      },
    };
  },
};
