import { Module } from '@pkg/config';
import { NextRouter } from 'next/router';
import { TranslationKey } from '../../@types/resources';

export const getModule = (router: NextRouter) => {
  return (router.pathname.split('/')[1] ?? '') as Module;
};

export const getRecordStatus = (record: any) => {
  return record.active ? 'dataTable.public' : 'dataTable.draft';
};

export const getTransKey = (key: TranslationKey) => {
  return key;
};
