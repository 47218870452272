import { Minimal, Task, TaskCreationBody, TaskUpdatingBody } from '@models/base';
import { Module } from '@pkg/config';
import { TaskStatus } from '@pkg/constants';
import {
  AcnButton,
  AcnCard,
  AutoForm,
  BreadcrumbItem,
  DropdownOptions,
  baseButton,
  baseScrollbar,
  fetchData,
  getModelValues,
  useFormModel,
  useTrans,
} from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { cloneDeep, set } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbar } from '../../components';
import { getModule } from '../../helpers/utils';
import { ModuleActions } from '../../types/common';
import { TaskApi } from './api';
import { TaskEditModel } from './edit.model';
import { TaskStatusLabel } from './type';

export const EditTaskContainer = () => {
  const { t } = useTrans();
  const router = useRouter();
  const taskApi = TaskApi();

  const id = router.query.id as string;
  const action = router.query.action as ModuleActions;

  const { data, refetch } = useQuery<Task>({
    queryKey: ['task', id],
    queryFn: () => taskApi.getById(id).then((rs) => rs.data),
    enabled: !!id,
  });

  const formModel = useFormModel(TaskEditModel.model, {});

  const form = useForm<TaskUpdatingBody>({
    mode: 'all',
    defaultValues: getModelValues(TaskEditModel.model),
  });

  const targetValue = form.watch('target') as Module;

  const { data: categories } = useQuery<Minimal[]>({
    queryKey: ['task-parent', targetValue],
    queryFn: () => {
      if (targetValue === 'posts') {
        return fetchData({ endpoint: 'post-categories' }).then((rs) => {
          if (!rs.data) return [];
          return rs.data;
        });
      }
      return [];
    },
    enabled: !!targetValue && !!formModel.model,
  });

  useEffect(() => {
    if (data && categories && formModel.model) {
      const model = cloneDeep(formModel.model);
      const options: DropdownOptions[] = categories.map((r) => ({ label: r.name, value: r._id }));
      set(model, 'parent.options', options);
      Object.keys(model).forEach((fieldName: string) => {
        set(model, `${fieldName}.value`, data[fieldName as keyof Task]);
      });
      formModel.setModel(model);
      form.reset({ ...data, parent: data.parent?._id });
    }
  }, [categories && data]);

  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('task.title'), href: '/' + getModule(router) },
    { title: t('task.form.editTitle') },
  ];

  const onUpdate = async (data: TaskUpdatingBody) => {
    const res = await taskApi.update(id, data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    refetch();
  };

  const onDuplicate = async (data: TaskCreationBody) => {
    const res = await taskApi.create(data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('duplicateSuccess'));
    router.push('/' + getModule(router));
  };

  const onTest = async (data: TaskCreationBody) => {
    const res = await taskApi.test(data);
    if (res.error) return showErrorNoti(res.error.message);
    showSuccessNoti(t('task.testSuccess'));
    await taskApi.updateStatus(id, TaskStatus.verify);
    refetch();
  };

  if (action !== 'edit' && action !== 'duplicate') router.push('/' + getModule(router));

  return (
    <div className="space-y-3">
      <Toolbar items={breadcrumb} />
      <div className={cn('flex gap-3 h-[calc(100vh-175px)]', baseScrollbar)}>
        <FormProvider {...form}>
          <div className={cn('flex-1 space-y-3')}>
            <AcnCard>
              <AcnCard.Title>{t('details')}</AcnCard.Title>
              <AcnCard.Content className="min-h-[calc(100vh-221px)]">
                <div className="space-y-4">
                  <AutoForm
                    model={formModel.model}
                    excludeFields={['type', 'target', 'parent']}
                    setModel={formModel.setModel}
                  />
                </div>
              </AcnCard.Content>
            </AcnCard>
          </div>

          <div className="w-[400px] space-y-3">
            <AcnCard>
              <AcnCard.Title>{t('publish')}</AcnCard.Title>
              <AcnCard.Content className="space-y-4">
                <div>
                  <label className="font-semibold">{t('task.form.fields.status.label')}:</label>{' '}
                  {data?.status && t(TaskStatusLabel[data.status] as any)}
                </div>
                <div className="space-x-4">
                  {action === 'edit' && (
                    <>
                      <AcnButton
                        className={cn(baseButton, '!bg-blue-500 !text-white')}
                        onClick={form.handleSubmit(onUpdate)}
                      >
                        {t('editBtn')}
                      </AcnButton>
                      <AcnButton onClick={form.handleSubmit(onTest)}>{t('task.testBtn')}</AcnButton>
                    </>
                  )}
                  {action === 'duplicate' && (
                    <AcnButton
                      className={cn(baseButton, '!bg-blue-500 !text-white')}
                      onClick={form.handleSubmit(onDuplicate)}
                    >
                      {t('duplicateBtn')}
                    </AcnButton>
                  )}
                </div>
              </AcnCard.Content>
            </AcnCard>

            <AcnCard>
              <AcnCard.Content className="space-y-4">
                <AutoForm model={formModel.model} fields={['type', 'target', 'parent']} setModel={formModel.setModel} />
              </AcnCard.Content>
            </AcnCard>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};
