import { getTransKey } from '../../helpers/utils';
import { SettingEditFieldModel } from './type';

type SettingEditModelProps = {};

export const SettingEditModel = {
  model: (props: SettingEditModelProps = {}): SettingEditFieldModel => {
    return {
      name: {
        label: getTransKey('setting.fields.name.label'),
        placeholder: getTransKey('setting.fields.name.placeholder'),
        inputType: 'Input',
        name: 'name',
        rules: { required: getTransKey('setting.fields.name.required') },
      },
      value: {
        label: getTransKey('setting.fields.value.label'),
        placeholder: getTransKey('setting.fields.value.placeholder'),
        inputType: 'Input',
        name: 'value',
        rules: {},
      },
    };
  },
};
