import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const formatDate = (date: Date, withHour: boolean = true) => {
  return dayjs(date).format(withHour ? 'DD-MM-YYYY - HH:mm:ss' : 'DD-MM-YYYY');
};

export const formatDateTimezone = (dateTime: Date) => {
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(advanced);
  return dayjs(dateTime).tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DDThh:mm:ssZ');
};
