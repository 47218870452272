import { Cronjob, CronjobQueryParams, Task } from '@models/base';
import { ICronJobStatus } from '@pkg/constants';
import { BreadcrumbItem, useQueryFilter, useTrans } from '@pkg/ui';
import { formatDate, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { TableColumnsType } from 'antd';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Toolbar } from '../../components';
import { AdminDataTable } from '../../components/AdminDataTable';
import { getModule } from '../../helpers/utils';
import { taskTypeLabel } from '../tasks/type';
import { CronjobApi } from './api';
import { cronJobStatus, timeCronLabel } from './type';

export const CronjobContainer = () => {
  const { t } = useTrans();
  const cronjobApi = CronjobApi();
  const router = useRouter();
  const breadcrumb: BreadcrumbItem[] = [{ title: t('dashboard.title'), href: '/' }, { title: t('cronjob.title') }];
  const [selected, setSelected] = useState<string[]>([]);
  const { filter, setFilter } = useQueryFilter<CronjobQueryParams>('cronjobs', { deleted: false });

  const { data: cronjobs, refetch } = useQuery({
    queryKey: ['cronjobs', filter],
    queryFn: () => cronjobApi.get(filter).then((rs) => rs.data || []),
  });

  const onCreate = () => {
    const module = getModule(router);
    router.push(`/${module}/create`);
  };

  const onUpdate = (row: Cronjob) => {
    const module = getModule(router);
    router.push(`/${module}/${row._id}/edit`);
  };

  const onRestore = async (row?: Cronjob) => {
    if (row) {
      const res = await cronjobApi.restore([row._id!]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreSuccess', { name: row.task.name }));
    } else {
      const res = await cronjobApi.restore(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('restoreItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const onRemove = async (row?: Cronjob) => {
    if (row) {
      const res = await cronjobApi.remove([row._id!]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.task.name }));
    } else {
      const res = await cronjobApi.remove(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const onDelete = async (row?: Cronjob) => {
    if (row) {
      const res = await cronjobApi.delete([row._id!]);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteSuccess', { name: row.task.name }));
    } else {
      const res = await cronjobApi.delete(selected);
      if (res.error) return showErrorNoti(res.error.message);
      showSuccessNoti(t('deleteItemsSuccess', { count: selected.length }));
      setSelected([]);
    }
    refetch();
  };

  const columns: TableColumnsType<Cronjob> = [
    { title: t('cronjob.fields.time.label'), dataIndex: 'time', render: (data) => timeCronLabel[data] },
    {
      title: t('task.title'),
      dataIndex: 'task',
      render: (data: Task) => data.name,
    },
    {
      title: t('task.type'),
      dataIndex: 'task',
      render: (data: Task) => t(taskTypeLabel[data.type] as any),
    },
    {
      title: t('cronjob.fields.status.label'),
      dataIndex: 'status',
      render: (data: ICronJobStatus) => t(cronJobStatus[data] as any),
    },
    { title: t('dataTable.createdDate'), render: (row: Cronjob) => formatDate(row.created!) },
    { title: t('dataTable.updatedDate'), render: (row: Cronjob) => formatDate(row.updated!) },
  ];

  return (
    <div className="space-y-3">
      <Toolbar
        deleted={filter.deleted}
        items={breadcrumb}
        onCreate={onCreate}
        onRestore={onRestore}
        onRemove={onRemove}
        onDelete={onDelete}
        setFilter={setFilter}
        selected={selected}
      />

      <div className="bg-secondary p-4 h-[calc(100vh-178px)] overflow-y-auto">
        <AdminDataTable<Cronjob>
          data={cronjobs?.data || []}
          pages={cronjobs?.pages}
          deleted={filter.deleted}
          columns={columns}
          onEdit={onUpdate}
          onRestore={onRestore}
          onRemove={onRemove}
          onDelete={onDelete}
          onSelected={setSelected}
          rowsSelected={selected}
        />
      </div>
    </div>
  );
};
