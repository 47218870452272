import { slugGenerate } from '@pkg/utils';
import { getTransKey } from '../../../base';
import { PostCreationFieldModel } from './type';

type PostCreationModelProps = {};

export const PostCreationModel = {
  model: (props: PostCreationModelProps = {}): PostCreationFieldModel => {
    return {
      title: {
        label: getTransKey('post.fields.title.label'),
        placeholder: getTransKey('post.fields.title.placeholder'),
        inputType: 'Input',
        name: 'title',
        sideEffect: ['slug'],
        rules: { required: getTransKey('post.fields.title.required') },
      },
      slug: {
        label: getTransKey('post.fields.slug.label'),
        placeholder: getTransKey('post.fields.slug.placeholder'),
        inputType: 'Input',
        name: 'slug',
        trigger: (fields: Record<keyof PostCreationFieldModel, string | number | boolean | undefined>) => {
          return fields.title ? slugGenerate(fields.title.toString()) : fields.title;
        },
        rules: { required: getTransKey('post.fields.slug.required') },
      },
      description: {
        label: getTransKey('post.fields.description.label'),
        placeholder: getTransKey('post.fields.description.placeholder'),
        inputType: 'TextArea',
        name: 'description',
        rules: { required: getTransKey('post.fields.description.required') },
      },
      content: {
        label: getTransKey('post.fields.content.label'),
        placeholder: getTransKey('post.fields.content.placeholder'),
        inputType: 'Editor',
        name: 'content',
        rules: { required: getTransKey('post.fields.content.required') },
      },
      image: {
        label: getTransKey('post.fields.image.label'),
        placeholder: getTransKey('post.fields.image.placeholder'),
        inputType: 'Image',
        name: 'image',
        rules: { required: getTransKey('post.fields.image.required') },
      },
      gallery: {
        label: getTransKey('post.fields.gallery.label'),
        inputType: 'MultipleImage',
        name: 'gallery',
        rules: {},
      },
      tags: {
        label: getTransKey('post.fields.tags.label'),
        placeholder: getTransKey('post.fields.tags.placeholder'),
        inputType: 'Dropdown',
        name: 'tags',
        options: [],
        rules: {},
      },
      codes: {
        label: getTransKey('post.fields.codes.label'),
        inputType: 'Input',
        name: 'codes',
        rules: {},
      },
      sourceUrl: {
        label: getTransKey('post.fields.sourceUrl.label'),
        placeholder: getTransKey('post.fields.sourceUrl.placeholder'),
        inputType: 'Input',
        name: 'sourceUrl',
        rules: {},
      },
      seoTitle: {
        label: getTransKey('post.fields.seoTitle.label'),
        placeholder: getTransKey('post.fields.seoTitle.placeholder'),
        inputType: 'Input',
        name: 'seoTitle',
        rules: {},
      },
      seoDescription: {
        label: getTransKey('post.fields.seoDescription.label'),
        placeholder: getTransKey('post.fields.seoDescription.placeholder'),
        inputType: 'TextArea',
        name: 'seoDescription',
        rules: {},
      },
    };
  },
};
