import { getTransKey } from '../../helpers/utils';
import { CronjobCreationFieldModel, timeCronOptions } from './type';

type CronjobCreationModelProps = {};

export const CronjobCreationModel = {
  model: (props: CronjobCreationModelProps = {}): CronjobCreationFieldModel => {
    return {
      time: {
        label: getTransKey('cronjob.fields.time.label'),
        placeholder: getTransKey('cronjob.fields.time.placeholder'),
        inputType: 'Dropdown',
        name: 'time',
        options: timeCronOptions,
        rules: { required: getTransKey('cronjob.fields.time.required') },
      },
      task: {
        label: getTransKey('task.title'),
        placeholder: getTransKey('cronjob.fields.task.placeholder'),
        inputType: 'Dropdown',
        name: 'task',
        options: [],
        rules: { required: getTransKey('cronjob.fields.task.required') },
      },
    };
  },
};
