import { MemberCreationBody } from '@models/base';
import {
  AcnButton,
  AcnCard,
  AutoForm,
  BreadcrumbItem,
  baseButton,
  baseScrollbar,
  getModelValues,
  useFormModel,
  useTrans,
} from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbar } from '../../components';
import { getModule } from '../../helpers/utils';
import { MemberApi } from './api';
import { MemberCreationModel } from './create.model';

export const MemberCreateContainer = () => {
  const { t } = useTrans();
  const router = useRouter();
  const memberApi = MemberApi();

  const formModel = useFormModel(MemberCreationModel.model, {});

  const form = useForm<MemberCreationBody>({
    mode: 'all',
    defaultValues: getModelValues(MemberCreationModel.model),
  });

  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('member.title'), href: '/' + getModule(router) },
    { title: t('member.createTitle') },
  ];

  const onCreate = async (data: MemberCreationBody) => {
    const res = await memberApi.create(data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('createSuccess'));
    router.push('/' + getModule(router));
  };

  return (
    <div className="space-y-3">
      <Toolbar
        items={breadcrumb}
        moreActions={
          <AcnButton
            disabled={!form.formState.isValid}
            className={cn(baseButton, '!bg-blue-500 !text-white')}
            onClick={form.handleSubmit(onCreate)}
          >
            {t('createBtn')}
          </AcnButton>
        }
      />
      <div className={cn('md:flex gap-3 h-[calc(100vh-175px)] space-y-3 md:space-y-0', baseScrollbar)}>
        <FormProvider {...form}>
          <div className={cn('flex-1 space-y-3')}>
            <AcnCard>
              <AcnCard.Title>{t('details')}</AcnCard.Title>
              <AcnCard.Content className="min-h-[calc(100vh-230px)]">
                <div className="space-y-4">
                  <AutoForm model={formModel.model} setModel={formModel.setModel} />
                </div>
              </AcnCard.Content>
            </AcnCard>
          </div>

          <div className="md:w-[400px] w-full space-y-3"></div>
        </FormProvider>
      </div>
    </div>
  );
};
