import { blockWidgetOptions } from '@pkg/config';
import { getTransKey } from '../../helpers/utils';
import { WidgetCreationFieldModel } from './type';

type WidgetCreationModelProps = {};

export const WidgetCreationModel = {
  model: (props: WidgetCreationModelProps = {}): WidgetCreationFieldModel => {
    return {
      name: {
        label: getTransKey('widget.fields.name.label'),
        placeholder: getTransKey('widget.fields.name.placeholder'),
        inputType: 'Input',
        name: 'name',
        rules: { required: getTransKey('widget.fields.name.required') },
      },
      key: {
        label: getTransKey('widget.fields.key.label'),
        placeholder: getTransKey('widget.fields.key.placeholder'),
        inputType: 'Input',
        name: 'key',
        rules: { required: getTransKey('widget.fields.key.required') },
      },
      position: {
        label: getTransKey('widget.fields.position.label'),
        placeholder: getTransKey('widget.fields.position.placeholder'),
        inputType: 'Dropdown',
        name: 'position',
        options: blockWidgetOptions,
        hiddenLabel: true,
        type: 'multiple',
        rules: {},
      },
      items: {
        label: getTransKey('widget.fields.items.label'),
        inputType: 'Input',
        name: 'items',
        value: JSON.stringify([]),
        rules: {},
      },
    };
  },
};
