import { getTransKey } from '../../helpers/utils';
import { LoginFieldsModel } from '../account/types/login';

type LoginModelProps = {};

export const LoginModel = {
  model: (props: LoginModelProps = {}): LoginFieldsModel => {
    return {
      username: {
        label: getTransKey('loginForm.username.label'),
        placeholder: getTransKey('loginForm.username.placeholder'),
        inputType: 'Input',
        name: 'username',
        rules: { required: getTransKey('loginForm.username.required') },
      },
      password: {
        label: getTransKey('loginForm.password.label'),
        placeholder: getTransKey('loginForm.password.placeholder'),
        inputType: 'Password',
        name: 'password',
        rules: { required: getTransKey('loginForm.password.required') },
      },
    };
  },
};
