import { UserPasswordUpdatingBody } from '@models/base';
import { userToken } from '@pkg/constants';
import { AcnButton, useFormModel, useTrans, useUser } from '@pkg/ui';
import AutoForm from '@pkg/ui/src/autoForm/AutoForm';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { accountService } from './accountService';
import { ChangePassword } from './changePassword.model';

export const ChangePasswordForm = () => {
  const accountApi = accountService();
  const { user, setUser } = useUser();
  const { t } = useTrans();

  const formModel = useFormModel(ChangePassword.model, {});
  const form = useForm<UserPasswordUpdatingBody>();

  // TODO: need to retest and remove comment code
  const onSubmit = async (data: UserPasswordUpdatingBody) => {
    if (!user?._id) return;
    const res = await accountApi.changePassword(user._id, data);
    // const res = await fetchData({
    //   method: 'PATCH',
    //   endpoint: 'users/:id/property/password',
    //   body: data,
    //   hasAuthen: true,
    //   query: { id: user?._id },
    // });
    if (res.error) return showErrorNoti(res.error.message || t('changePasswordError'));
    showSuccessNoti(t('changePasswordSuccess'));
    localStorage.removeItem(userToken);
    setUser();
  };

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={cn('w-96 p-4 space-y-4')}>
          <AutoForm model={formModel.model} setModel={formModel.setModel} />
          <AcnButton loading={form.formState.isLoading} disabled={form.formState.isLoading} htmlType="submit">
            {t('changePasswordLabel')}
          </AcnButton>
        </form>
      </FormProvider>
    </div>
  );
};
