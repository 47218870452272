import { PostCreationBody } from '@models/news';
import { AutoFormModelResult, InputType, useTrans } from '@pkg/ui';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { PostCreationFieldModel } from './type';

type CodeProps = {
  formModel: AutoFormModelResult<PostCreationFieldModel>;
};

export const Codes = ({ formModel }: CodeProps) => {
  const { t } = useTrans();
  const codes = formModel.model?.codes;

  const { control } = useFormContext<PostCreationBody>();
  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'codes',
  });

  const add = () => {
    insert(0, { code: '', description: '', created: new Date().getTime() });
  };

  if (!codes) return <></>;

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        {t(codes.label! as any)}
        <Button size="small" className="!bg-green-500 !text-white border-0" onClick={add}>
          Thêm code
        </Button>
      </div>

      <div>
        {!isEmpty(fields) && (
          <div className="grid grid-cols-5 gap-2 mb-2">
            <div>
              Mã code <span className="text-red-500">*</span>
            </div>
            <div>Mô tả</div>
            <div>
              Ngày tạo <span className="text-red-500">*</span>
            </div>
            <div>Ngày hết hạn</div>
            <div>Hành động</div>
          </div>
        )}
        {fields.map((code, index) => {
          const CodeComponent = InputType['Input'];
          const DescriptionComponent = InputType['Input'];
          const CreatedComponent = InputType['DatePicker'];

          return (
            <div key={code.id} className="items-start block md:grid md:grid-cols-5 md:gap-2">
              <Controller
                control={control}
                name={`codes.${index}.code`}
                rules={{ required: 'Mã code is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <CodeComponent placeholder="Mã code..." value={value} onChange={onChange} error={error?.message} />
                  );
                }}
              />

              <Controller
                control={control}
                name={`codes.${index}.description`}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <DescriptionComponent
                      placeholder="Mô tả..."
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name={`codes.${index}.created`}
                rules={{ required: 'Ngày tạo is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <CreatedComponent
                      placeholder="Ngày tạo..."
                      value={value ? dayjs(value).format('YYYY-MM-DD') : null}
                      format="YYYY-MM-DD"
                      onChange={(date: string) => onChange(date ? dayjs(date).unix() * 1000 : null)}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name={`codes.${index}.expired`}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <CreatedComponent
                      placeholder="Ngày hết hạn..."
                      value={value ? dayjs(value).format('YYYY-MM-DD') : null}
                      format="YYYY-MM-DD"
                      onChange={(date: string) => onChange(date ? dayjs(date).unix() * 1000 : null)}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Button className="!bg-red-500 !text-white border-0 w-20" onClick={() => remove(index)}>
                Xoá
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
