import { User, UserPasswordUpdatingBody } from '@models/base';
import urlcat from 'urlcat';
import { adminApi } from '../../../apis/adminApi';

export const accountService = () => {
  return {
    async changePassword(id: string, data: UserPasswordUpdatingBody) {
      return adminApi.patch<User>(urlcat('users/:id/property/password', { id }), data);
    },
  };
};
