import { cacheModules } from '@pkg/config';
import { AcnButton, AcnCheckbox, AcnCheckboxProps } from '@pkg/ui';
import { showSuccessNoti } from '@pkg/utils';
import { useState } from 'react';
import { SettingApi } from './api';

export const Cache = () => {
  const settingApi = SettingApi();
  const [selected, setSelected] = useState<string[]>([]);
  const indeterminate = selected.length > 0 && selected.length < cacheModules.length;

  const onCheckAllChange: AcnCheckboxProps['onChange'] = (e) => {
    setSelected(e.target.checked ? cacheModules.map((r) => r.key) : []);
  };

  const onDelete = async () => {
    await settingApi.deleteCache({ modules: selected });
    showSuccessNoti('Delete all cache successfully!');
    setSelected([]);
  };

  return (
    <div className="space-y-4 lg:w-[400px]">
      <AcnCheckbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={selected.length === cacheModules.length}
      >
        Check all
      </AcnCheckbox>

      <div className="border-b border-gray-100 border-solid" />

      <AcnCheckbox.Group
        value={selected}
        onChange={setSelected}
        className="lg:flex lg:flex-wrap lg:[&_.ant-checkbox-group-item]:w-[49%] gap-2"
        options={cacheModules.map((r) => ({ ...r, value: r.key }))}
      />

      <div className="border-b border-gray-100 border-solid" />

      <AcnButton className="!text-white !bg-red-500 !border-0" onClick={onDelete}>
        Delete cache
      </AcnButton>
    </div>
  );
};
