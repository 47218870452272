import { Setting, SettingUpdatingBody } from '@models/base';
import { AcnButton, AcnDrawer, AutoForm, getModelValues, useFormModel, useTrans } from '@pkg/ui';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SettingEditModel } from './update.model';

type SettingUpdateDrawerProps = {
  data: Setting;
  onClose: () => void;
  onUpdate: (data: SettingUpdatingBody) => void;
};

export const SettingUpdateDrawer = ({ data, onClose, onUpdate }: SettingUpdateDrawerProps) => {
  const { t } = useTrans();
  const formModel = useFormModel(SettingEditModel.model, data);

  const form = useForm<SettingUpdatingBody>({
    mode: 'all',
    defaultValues: getModelValues(SettingEditModel.model),
  });

  useEffect(() => {
    form.setValue('name', data.name || '');
    form.setValue('value', data.value || '');
  }, [data]);

  return (
    <AcnDrawer
      title={t('setting.update.title')}
      onClose={onClose}
      width={500}
      open={!!data}
      extra={
        <div className="flex items-center gap-4">
          <AcnButton onClick={onClose}>{t('setting.update.cancelBtn')}</AcnButton>
          <AcnButton type="primary" onClick={form.handleSubmit(onUpdate)}>
            {t('setting.update.updateBtn')}
          </AcnButton>
        </div>
      }
    >
      <FormProvider {...form}>
        <AutoForm model={formModel.model} setModel={formModel.setModel} />
      </FormProvider>
    </AcnDrawer>
  );
};
