import { Member, MemberCreationBody } from '@models/base';
import {
  AcnButton,
  AcnCard,
  AutoForm,
  BreadcrumbItem,
  baseButton,
  baseScrollbar,
  getModelValues,
  useFormModel,
  useTrans,
} from '@pkg/ui';
import { cn, showErrorNoti, showSuccessNoti } from '@pkg/utils';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Toolbar } from '../../components';
import { getModule } from '../../helpers/utils';
import { useHotkey } from '../../hooks';
import { MemberApi } from './api';
import { MemberEditModel } from './edit.model';

export const EditMemberContainer = () => {
  const { t } = useTrans();
  const router = useRouter();
  const memberApi = MemberApi();

  const id = router.query.id as string;

  const { data } = useQuery<Member>({
    queryKey: ['member', id],
    queryFn: () => memberApi.getById(id).then((rs) => rs.data),
    enabled: !!id,
  });

  const formModel = useFormModel(MemberEditModel.model, {});

  const form = useForm<MemberCreationBody>({
    mode: 'all',
    defaultValues: getModelValues(MemberEditModel.model),
  });

  useEffect(() => {
    if (data) {
      form.reset(data);
    }
  }, [data]);

  const breadcrumb: BreadcrumbItem[] = [
    { title: t('dashboard.title'), href: '/' },
    { title: t('member.title'), href: '/' + getModule(router) },
    { title: t('member.editTitle') },
  ];

  const onUpdate = async (data: MemberCreationBody) => {
    const res = await memberApi.update(id, data);
    if (!res.data) return showErrorNoti(res.error.message);
    showSuccessNoti(t('updateSuccess'));
    router.push('/' + getModule(router));
  };

  useHotkey('ctrl+s', form.handleSubmit(onUpdate));

  return (
    <div className="space-y-3">
      <Toolbar
        items={breadcrumb}
        moreActions={
          <AcnButton
            disabled={!form.formState.isValid}
            className={cn(baseButton, '!bg-blue-500 !text-white')}
            onClick={form.handleSubmit(onUpdate)}
          >
            {t('editBtn')}
          </AcnButton>
        }
      />
      <div className={cn('md:flex gap-3 h-[calc(100vh-175px)] space-y-3 md:space-y-0', baseScrollbar)}>
        <FormProvider {...form}>
          <div className={cn('flex-1 space-y-3')}>
            <AcnCard>
              <AcnCard.Title>{t('details')}</AcnCard.Title>
              <AcnCard.Content className="min-h-[calc(100vh-230px)]">
                <div className="space-y-4">
                  <AutoForm model={formModel.model} setModel={formModel.setModel} />
                </div>
              </AcnCard.Content>
            </AcnCard>
          </div>

          <div className="md:w-[400px] w-full space-y-3"></div>
        </FormProvider>
      </div>
    </div>
  );
};
